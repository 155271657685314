import { mapActions } from "vuex";
import expandRow from "@/components/Exchange/Expand/index";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import OpenOrderTable from "@/components/OpenOrders/OpenOrderTable/index";

export default {
  components: {
    expandRow,
    PersianDatePicker: VuePersianDatetimePicker,
    OpenOrderTable,
  },
  data() {
    return {
      value: false,
      styles: {
        height: "100vh",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        backgroundColor: "#141e2c",
      },
      drawerWidth: window.innerWidth >= 769 ? window.innerWidth >= 1000 ? "25%" : "30%" : "100%",
      loading: false,
      pageSize: 10,
      pageNo: 1,
      total: 10,
      symbol: [],
      coinScales: {},
      orderTypes: [
        {
          value: "limit",
          text: this.$t("openOrders.limit"),
        },
        {
          value: "market",
          text: this.$t("openOrders.market"),
        },
        {
          value: "stop_limit",
          text: this.$t("openOrders.stopLimit"),
        },
        {
          value: "oco",
          text: this.$t("openOrders.oco"),
        },
      ],
      // orderType: "",
      formItem: {
        symbol: "",
        type: "",
        source: "",
        direction: "",
        date: "",
        orderType: "",
      },
      orders: [],
    };
  },
  computed: {
    lang: function () {
      return this.getLang;
    },
  },
  watch: {
    lang: function () {
      this.updateLangData();
    },
  },
  created() {
    this.formItem.date = new Date().getTime();
    this.setNavigation("nav-open-orders");
    this.getCurrentOrders();
    this.getSymbol();
  },
  mounted() {},
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      geOrdersCurrentList: "exchange/GET_ORDERS_CURRENT_LIST_SERVICE",
      orderCancel: "exchange/CANCEL_ORDER_SERVICE",
      getSymbolsThumb: "market/GET_SYMBOL_THUMB_SERVICE",
    }),
    clearSelect(e, key) {
      e.stopPropagation()
      this.formItem[key] = ""
      if (key === 'orderType') {
        this.formItem.type = ""
        this.formItem.source = ""
      }
    },
    loadDataPage(data) {
      this.pageNo = data;
      this.getCurrentOrders();
    },
    handleSubmit() {
      this.pageNo = 1;
      this.getCurrentOrders();
      this.value = false;
    },
    handleClear() {
      // this.orderType = "";
      this.formItem = {
        symbol: "",
        type: "",
        source: "",
        direction: "",
        orderType: "",
        date: new Date().getTime(),
      };
      this.handleSubmit();
    },
    setSource(key) {
      if (key === "limit") {
        this.formItem.type = "LIMIT_PRICE";
        this.formItem.source = "MANUAL_ORDER";
      } else if (key === "stop_limit") {
        this.formItem.type = "LIMIT_PRICE";
        this.formItem.source = "STOP_LIMIT_ORDER";
      } else if (key === "market") {
        this.formItem.type = "MARKET_PRICE";
        this.formItem.source = "MANUAL_ORDER";
      } else if (key === "oco") {
        this.formItem.type = "LIMIT_PRICE";
        this.formItem.source = "OCO_ORDER";
      }
    },
    getCurrentOrders() {
      this.loading = true;
      const {
        symbol,
        type,
        source,
        direction,
        date: rangeDate,
      } = this.formItem;
      let startTime = rangeDate[0] || "";
      let endTime = rangeDate[1] || "";
      let payload = {
        symbol: symbol,
        direction: direction,
        type: type,
        source: source,
        startTime: startTime,
        endTime: endTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      Object.keys(payload).forEach((key) => {
        // console.log("key", key, payload[key]);
        if (payload[key] === "" || payload[key] === undefined) {
          delete payload[key];
        }
      });
      // console.log("payload", payload)
      this.orders = [];
      this.geOrdersCurrentList(payload)
        .then((response) => {
          let ordersCurrentList = response.data.content;
          this.total = response.data.totalElements;
          let rows = [];
          if (ordersCurrentList.length > 0) {
            for (let i = 0; i < ordersCurrentList.length; i++) {
              let row = ordersCurrentList[i];
              row.price =
                row.type === "MARKET_PRICE"
                  ? this.$t("exchange.marketprice")
                  : row.price;
              rows.push(row);
            }
            this.orders = rows;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSymbol() {
      this.getSymbolsThumb().then((response) => {
        if (response.data.length > 0) {
          this.symbol = response.data;
        }
      });
    },
    updateLangData() {
      this.columns[1].title = this.$t("exchange.time");
      this.columns[2].title = this.$t("uc.finance.trade.symbol");
      this.columns[3].title = this.$t("uc.finance.trade.type");
      this.columns[4].title = this.$t("exchange.direction");
      this.columns[5].title = this.$t("exchange.price");
      this.columns[6].title = this.$t("exchange.num");
      this.columns[7].title = this.$t("exchange.traded");
      this.columns[8].title = this.$t("uc.finance.trade.turnover");
      this.columns[9].title = this.$t("exchange.action");
    },
    cancelCreatedOrder(orderId) {
      this.orderCancel(orderId)
        .then((response) => {
          this.getCurrentOrders();
          this.$toast.success(this.$t("openOrders.successCancel"));
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};

import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/UI/LoadingComp"

export default {
  name: "open-order-table",
  components: {
    Loader
  },
  props: {
    dataTable: {
      type: Array,
    },
    current: {
      type: Number,
    },
    total: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      order: {},
      orderDetailsData: [],
      isOrderDetailModalOpen: false,
      orderStopLimitDetailsData: [],
      isOrderStopLimitModalOpen: false,
      cancelOrderModal: false,
      cancelOrderId: "",
      detailsBtnLoading: null,
      orderDetailColumns: [
        {
          title: this.$t("openOrders.amount"),
          key: "amount",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", this.numberWithCommas(params.row.amount));
          },
        },
        {
          title: this.$t("openOrders.price"),
          key: "price",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("openOrders.time"),
          key: "time",
          align: "center",
          sortable: true,
          minWidth: 180,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("openOrders.turnOver"),
          key: "turnover",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            return h("span", this.numberWithCommas(params.row.turnover));
          },
        },
      ],
      columns: [
        {
          type: "index",
          align: "center",
          width: 50,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                skin: params.row.skin,
                rows: params.row.detail,
              },
            });
          },
        },
        {
          title: this.$t("openOrders.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("openOrders.pairCoin"),
          width: 150,
          key: "symbol",
          align: "center",
        },
        {
          title: this.$t("openOrders.orderType"),
          width: 150,
          key: "type",
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.source === "STOP_LIMIT_ORDER" ||
                params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("openOrders.stopLimit")
                : params.row.type === "LIMIT_PRICE" &&
                  params.row.source === "MANUAL_ORDER"
                ? this.$t("openOrders.limitOrder")
                : params.row.type === "MARKET_PRICE" &&
                  params.row.source === "MANUAL_ORDER"
                ? this.$t("openOrders.marketOrder")
                : params.row.source === "OCO_ORDER"
                ? this.$t("openOrders.ocoLimit")
                : params.row.source === "OCO_STOP_LIMIT_ORDER" ||
                  params.row.source === "OCO_CONDITION"
                ? this.$t("openOrders.ocoStopLimit")
                : "--"
            );
          },
        },
        {
          title: this.$t("openOrders.tradeDirection"),
          key: "direction",
          width: 120,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("openOrders.buy")
                : this.$t("openOrders.sell")
            );
          },
        },
        {
          title: this.$t("openOrders.price"),
          key: "price",
          width: 140,
          sortable: true,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              {
                attrs: {
                  title: params.row.price,
                },
              },
              this.numberWithCommas(
                params.row.price,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("openOrders.amount"),
          key: "amount",
          sortable: true,
          width: 215,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    title: params.row.amount,
                  },
                },
                this.numberWithCommas(
                  params.row.amount,
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.baseSymbol
                    : params.row.coinSymbol
                  : params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("openOrders.tradedAmount"),
          key: "tradedAmount",
          sortable: true,
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    title: params.row.tradedAmount,
                  },
                },
                this.numberWithCommas(
                  params.row.tradedAmount,
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("openOrders.tradedPercent"),
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("span", params.row.tradedPercentage + "%");
          },
        },
        {
          title: this.$t("openOrders.averageTradePrice"),
          sortable: true,
          key: "averagePrice",
          width: 180,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.averagePrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("openOrders.turnOver"),
          sortable: true,
          key: "turnover",
          width: 215,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    title: params.row.turnover,
                  },
                },
                this.numberWithCommas(
                  params.row.turnover,
                  this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.baseSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("openOrders.refCode"),
          key: "trackingCode",
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("openOrders.operation"),
          key: "operation",
          slot: "operation",
          width: 180,
          align: "center",
          },
      ],
      orderStopLimitDetailsColumns: [
        {
          title: this.$t("openOrders.amount"),
          key: "amount",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.amount));
          },
        },
        {
          title: this.$t("openOrders.price"),
          key: "price",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("openOrders.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("openOrders.turnOver"),
          key: "turnover",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.turnover));
          },
        },
      ],
      preconditionDetailsColumns: [
        {
          title: this.$t("openOrders.amount"),
          key: "amount",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.amount));
          },
        },
        {
          title: this.$t("openOrders.price"),
          key: "price",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("openOrders.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("openOrders.turnOver"),
          key: "turnover",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.turnover));
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  methods: {
    ...mapActions({
      getPreconditionOrderDetailByOrderID:
        "exchange/GET_PRECONDITION_ORDER_DETAIL_BY_ORDER_ID_SERVICE",
      getOrderDetailsService: "exchange/GET_ORDER_DETAILS_SERVICE",
    }),
    cancelOrder(orderId) {
      if (this.detailsBtnLoading !== null) return;
        this.cancelOrderModal = true;
        this.cancelOrderId = orderId;
    },
    getDetails(data) {
      if (this.detailsBtnLoading !== null) return;
      if (
          data.source === "MANUAL_ORDER" ||
          data.source === "OCO_ORDER"
      ) {
        this.getOrderDetails(data.orderId);
      } else if (
          data.source === "STOP_LIMIT_ORDER" ||
          data.source === "OCO_STOP_LIMIT_ORDER"
      ) {
        this.getStopLimitOrderDetail(data.orderId);
      }
    },
    dateFormat: function (tick) {
      return this.$options.filters.dateFormat(tick);
    },
    changePageNumber(pageIndex) {
      this.$emit("changePage", pageIndex);
    },
    getStopLimitOrderDetail(orderId) {
      this.detailsBtnLoading = orderId;
      this.getPreconditionOrderDetailByOrderID({ id: orderId }).then((response) => {
        this.orderStopLimitDetailsData = response.data.data;
        this.isOrderStopLimitModalOpen = true;
      }).finally(() => {
        this.detailsBtnLoading = null;
      })
    },
    getOrderDetails(orderId) {
      this.detailsBtnLoading = orderId;
      this.getOrderDetailsService(orderId).then((response) => {
        this.orderDetailsData = response.data.data.detail;
        this.order = response.data.data.order;
        this.isOrderDetailModalOpen = true;
      }).finally(() => {
        this.detailsBtnLoading = null;
      })
    },
    closeOrderDetailModal() {
      this.isOrderDetailModalOpen = false;
    },
    cancel(orderId) {
      this.$emit("cancelCreatedOrder", orderId);
      this.cancelOrderId = "";
      this.cancelOrderModal = false;
    },
  },
};

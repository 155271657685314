import { render, staticRenderFns } from "./OpenOrders.html?vue&type=template&id=774d4444&scoped=true&"
import script from "./OpenOrders.js?vue&type=script&lang=js&"
export * from "./OpenOrders.js?vue&type=script&lang=js&"
import style0 from "./open-orders.scss?vue&type=style&index=0&id=774d4444&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774d4444",
  null
  
)

export default component.exports